import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import Slide from '@material-ui/core/Slide'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Router, { useRouter } from 'next/router'
import Image from '@material-ui/icons/Image'
import Videocam from '@material-ui/icons/Videocam'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import TemplateReactions from '../TemplateReactions'
import { getLoggedInUser } from '../../graphql/account'
import {
  TemplateContainer,
  TemplateOverLay,
  StyledChip
} from './Template.styles'
import TemplateControls from '../TemplateControls'

import {
  MasonryImage,
  MasonryThumbnailContainer,
  MasonryThumbnailBox
} from '../MasonryThumbnail/MasonryThumbnail.styles'

const AI_EXTRA_PLANS = [
  'A.I. Summer Collection',
  'A.I. Summer Freebies',
  'A.I. Summer Animated Collection'
]
const getColor = (badgeLabel) => {
  switch (badgeLabel) {
    case 'PRO':
      return 'secondary'
    case 'ACE':
      return '#b30041'
    case 'GURU':
      return '#660025'
    default:
      return 'secondary'
  }
}
const getBadgeLabel = (
  planIsAI,
  userExtraSubscriptions,
  userHasAIImagePlan,
  userHasAIVideoPlan,
  templateHasAIImagePlan,
  templateHasAIVideoPlan
) => {
  if (
    !planIsAI &&
    !userExtraSubscriptions.some((extra) => AI_EXTRA_PLANS.includes(extra))
  ) {
    return 'PRO'
  }
  if (!userHasAIImagePlan && templateHasAIImagePlan) {
    return 'ACE'
  }
  if (!userHasAIVideoPlan && templateHasAIVideoPlan) {
    return 'GURU'
  }
  return 'PRO'
}
const assetIcons = {
  imageAssets: Image,
  textAssets: TextFieldsIcon,
  videoAssets: PlayArrowIcon
}
const formatDuration = (duration) => {
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}

const Template = ({ data, hideInfo, refetchFavorites }) => {
  const containerRef = useRef()
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerSize({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight
      })
    }
  }, [])

  const { pathname } = useRouter()
  const {
    data: { me }
  } = useQuery(getLoggedInUser, {
    ssr: false,
    fetchPolicy: 'cache-only'
  })

  const {
    id,
    userLike,
    favorited,
    thumbnailUrl,
    name,
    type,
    size,
    // newBadge,
    hasAccess,
    subscriptions,
    assetCount,
    duration
  } = data

  // Subscriptions
  const {
    plan: {
      subscriptionParent: { name: userMainPlan }
    }
  } = me
  const userExtraSubscriptions = me.extras.map(
    (extraPlans) => extraPlans.subscriptionParent.name
  )
  const templateExtraPlans = subscriptions
    ?.filter(
      (subscription) => subscription.type.typeName === 'ExtraSubscription'
    )
    .map((subscription) => subscription.name)

  // Plans
  const planIsAI = userMainPlan === 'AI'
  const userHasAIImagePlan = userExtraSubscriptions.includes(
    'A.I. Summer Collection'
  )
  const userHasAIVideoPlan = userExtraSubscriptions.includes(
    'A.I. Summer Animated Collection'
  )
  const templateHasAIImagePlan = templateExtraPlans?.includes(
    'A.I. Summer Collection'
  )
  const templateHasAIVideoPlan = templateExtraPlans?.includes(
    'A.I. Summer Animated Collection'
  )
  const badgeLabel = getBadgeLabel(
    planIsAI,
    userExtraSubscriptions,
    userHasAIImagePlan,
    userHasAIVideoPlan,
    templateHasAIImagePlan,
    templateHasAIVideoPlan
  )
  const [showReactions, setShowReactions] = useState(false)

  const [loaded, setLoaded] = useState(false)
  const handleLoad = () => setLoaded(true)

  const onTemplateEditClick = () => {
    if (hasAccess)
      Router.push('/editor', `/editor/create/${id}`, {
        query: { id, type: type.slug }
      })
  }

  const { width, height } = size
  const ratio = containerSize.width / width

  return (
    <>
      <TemplateContainer
        ref={containerRef}
        onMouseEnter={() => setShowReactions(true)}
        onMouseLeave={() => setShowReactions(false)}
      >
        <Box
          position='absolute'
          display='flex'
          alignSelf='flex-end'
          p={1}
          zIndex={1}
        >
          {!hasAccess && (
            <StyledChip
              size='small'
              label={
                <Typography variant='overline' style={{ color: '#fff' }}>
                  {badgeLabel}
                </Typography>
              }
              color={getColor(badgeLabel)}
            />
          )}
          {/* {newBadge && (
            <Chip
              size='small'
              label={<Typography variant='overline'>new</Typography>}
              color='secondary'
            />
          )} */}
        </Box>

        <MasonryThumbnailContainer
          width={width * ratio}
          height={height * ratio}
        >
          <Fade in={loaded}>
            <MasonryThumbnailBox>
              <MasonryImage
                src={thumbnailUrl}
                hidden={!loaded}
                onLoad={handleLoad}
                onClick={() => {}}
                // crossOrigin='anonymous'
              />
              {!loaded && (
                <Skeleton
                  variant='rect'
                  animation='wave'
                  width={width * ratio}
                  height={height * ratio}
                />
              )}
            </MasonryThumbnailBox>
          </Fade>
        </MasonryThumbnailContainer>
        {/* <img
          src={thumbnailUrl}
          width='100%'
          alt={name}
          // crossOrigin='anonymous'
        /> */}
        {!hideInfo && (
          <Box p={1}>
            <Typography variant='subtitle2'>{name}</Typography>
            <Box marginTop={1} display='flex' justifyContent='flex-end'>
              {type.typeName === 'ImageTemplate' ? (
                <Image color='disabled' fontSize='small' />
              ) : (
                <Videocam color='disabled' fontSize='small' />
              )}
            </Box>
            {assetCount && (
              <Box display='flex' justifyContent='flex-start' marginTop={1}>
                {type.typeName === 'VideoTemplate' && (
                  <Box mr={1} display='flex'>
                    <WatchLaterIcon
                      color='disabled'
                      fontSize='small'
                      style={{ marginRight: 2 }}
                    />
                    <Typography variant='body2'>
                      {formatDuration(duration)}
                    </Typography>
                  </Box>
                )}
                <Box display='flex'>
                  {Object.entries(assetCount).map(([assetType, count]) => {
                    if (assetType === '__typename') {
                      return null
                    }
                    const AssetIcon = assetIcons[assetType]
                    return (
                      <Box
                        key={assetType}
                        display='flex'
                        alignItems='center'
                        mr={1}
                      >
                        <Typography variant='body2'>{count || 0}</Typography>
                        {AssetIcon ? (
                          <AssetIcon color='disabled' fontSize='small' />
                        ) : null}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )}
          </Box>
        )}
        {me && (
          <Slide
            direction='right'
            in={showReactions}
            mountOnEnter
            unmountOnExit
          >
            <Box position='absolute'>
              <TemplateReactions
                reactions={{ id, userLike, favorited }}
                refetchFavorites={refetchFavorites}
              />
            </Box>
          </Slide>
        )}
        <Slide direction='up' in={showReactions} mountOnEnter unmountOnExit>
          <Box
            position='absolute'
            width='100%'
            height='100%'
            display='flex'
            justifyContent='flex-end'
            alignItems='flex-end'
          >
            <TemplateControls
              onPreview={() =>
                Router.push(
                  `${pathname}?dialogTemplateId=${id}`,
                  `/templates/preview/${id}`
                )
              }
              onEdit={() => onTemplateEditClick()}
              user={me}
              hasAccess={hasAccess}
            />
          </Box>
        </Slide>
        <Fade in={showReactions}>
          <TemplateOverLay
            onClick={() =>
              Router.push(
                `${pathname}?dialogTemplateId=${id}`,
                `/templates/preview/${id}`
              )
            }
          />
        </Fade>
      </TemplateContainer>
    </>
  )
}

Template.propTypes = {
  data: PropTypes.object,
  hideInfo: PropTypes.bool
}

Template.defaultProps = {
  data: {},
  hideInfo: false
}

export default Template
